<template>
  <div class="chart">
    <div class="chart-tabs">
      <van-tabs v-model="active" color="#51CCB4" line-width="30" line-height="2" @click="checkWay">
        <van-tab title="收入" title-class="chart-tab"> </van-tab>
        <van-tab title="支出" title-class="chart-tab"> </van-tab>
      </van-tabs>
    </div>
    <div class="date-tabs">
      <van-tabs
        v-model="activeDate"
        type="card"
        background="rgba(118,118,128,0.12)"
        itle-active-color="#fff"
        @click="checkWay"
      >
        <van-tab title="周"></van-tab>
        <van-tab title="月"></van-tab>
        <van-tab title="年"></van-tab>
      </van-tabs>
    </div>
    <div class="broken-line echar-box">
      <BrokenLine :broken-data="BrokenData" />
    </div>
    <div class="pie-chart echar-box">
      <PieChart :pie-data="pieData" />
    </div>

    <div class="histogram echar-box">
      <Histogram :histo-data="histoData" />
    </div>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';

import BrokenLine from '@/components/Charts/BrokenLine.vue';
import PieChart from '@/components/Charts/PieChart.vue';
import Histogram from '@/components/Charts/Histogram.vue';

import { showLoading, hideLoading } from '@/utils/VantUtils';
import { handlerEror } from '@/utils/Tools';

import Http from '@/utils/Http.js';

const goMain = 'cloud/ec/tally/getChartData';

export default {
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    BrokenLine,
    PieChart,
    Histogram
  },
  data() {
    return {
      active: 0,
      activeDate: 0,
      pieData: [],
      histoData: {
        tallyChartDataOfBar: [],
        timeStr: '',
        active: ''
      },
      BrokenData: []
    };
  },
  methods: {
    getData(type, billType) {
      showLoading();
      Http.get(goMain, { type, billType })
        .then((data) => {
          hideLoading();
          this.BrokenData = data.object.tallyChartDataOfLink.map((item) => {
            return {
              createDate: item.createDate,
              amount: Number(item.amount)
            };
          });
          this.pieData = data.object.tallyChartDataOfPie.map((item) => {
            return {
              const: 'const',
              type: item.operateName,
              money: Number(item.amount)
            };
          });
          this.histoData.tallyChartDataOfBar = data.object.tallyChartDataOfBar;
          this.histoData.timeStr = data.object.timeStr;
        })
        .catch((err) => {
          hideLoading();
          handlerEror(err);
        });
    },
    checkWay() {
      this.histoData.active = this.active;
      this.getData(this.activeDate + 1, this.active + 1);
    }
  },
  created() {
    this.checkWay();

    window['refresh'] = () => {
      this.checkWay();
    };
  }
};
</script>

<style lang="less" scoped>
.chart {
  padding: 0 20px;
  .chart-tabs {
    padding: 0 80px;
    /deep/ .van-tabs__wrap {
      .van-tabs__nav--line {
        padding-bottom: 5px;
      }
      .chart-tab {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .date-tabs {
    /deep/ .van-tabs__wrap {
      overflow: visible;
    }
    /deep/ .van-tabs__nav {
      align-items: center;
      border: 0;
      padding: 0 5px;
      height: 32px;
      margin: 0;
      .van-tab {
        border: 0;
        color: #000;
      }
      .van-tab--active {
        height: 80%;
        width: 90%;
        border-radius: 5px;
        background: #51ccb4;
        color: #fff;
      }
    }
    margin: 10px 0 20px;
  }
  .echar-box {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #eaebf0;
    border-radius: 7px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  }
  .pie-chart {
    margin: 15px auto 0;
  }
  .histogram {
    margin: 15px auto;
  }
}
</style>
