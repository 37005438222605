<template>
  <div class="pie">
    <p class="pie-title">饼状图</p>
    <canvas v-show="!showChart" id="pieChart" style="width: 100%; height: 300px;"></canvas>
    <div class="record" v-show="showChart">
      <van-image :src="require('@/assets/images/accounts/none-record.png')" width="39vw" />
      <p>暂无记录～</p>
    </div>
  </div>
</template>

<script>
import F2 from '@antv/f2/lib/index-all';

import { Image as VanImage } from 'vant';

export default {
  components: {
    VanImage
  },
  props: {
    pieData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chart: {},
      showChart: false
    };
  },

  methods: {
    boxCanvas() {
      this.chart = new F2.Chart({
        id: 'pieChart',
        pixelRatio: window.devicePixelRatio
      });

      if (!this.pieData.length) {
        this.showChart = true;
        return;
      }
      this.showChart = false;

      let count = 0;
      let pieData = this.pieData;

      pieData.forEach((item) => {
        count += item.money;
      });

      var map = new Map();

      pieData.forEach((item) => {
        map.set(item.type, Math.round((item.money / count) * 10000) / 100 + '%');
      });

      const chart = this.chart;

      chart.source(this.pieData);

      chart.coord('polar', {
        transposed: true,
        radius: 0.9,
        innerRadius: 0.5
      });
      chart.axis(false);
      chart.legend({
        position: 'bottom',
        align: 'center',
        itemFormatter: function itemFormatter(val) {
          return val + ' ' + map.get(val);
        },
        marker: {
          symbol: 'circle', // marker 的形状
          radius: 5 // 半径大小
        }
      });
      chart.tooltip(false);
      chart.guide().html({
        position: ['50%', '50%'],
        html: `
            <div style="text-align: center; width: 80px; height: 50px;">
              <p style="margin: 0;" id="title"></p>
              <p style="margin: 0; font-weight: bold;" id="money"></p>
            </div>
          `
      });

      chart
        .interval()
        .position('const*money')
        .adjust('stack')
        .color('type');

      chart.render();

      chart.interaction('pie-select', {
        animate: {
          duration: 300,
          easing: 'backOut'
        },
        onEnd(ev) {
          const { data, shapeInfo, selected } = ev;

          if (selected == false) {
            document.getElementById('title').innerHTML = '';
            document.getElementById('money').innerHTML = '';
            return;
          }
          if (selected == undefined) {
            return;
          }

          document.getElementById('title').innerHTML = `
            <span style="text-align: center; color: ${shapeInfo.color}; font-size: 18px">
              ${data.type}
            </span>
          `;
          document.getElementById('money').innerHTML = `
            <span style="text-align: center; color: #333; font-size: 16px">
              ${Math.round((data.money / count) * 10000) / 100 + '%'}
            </span>
          `;
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.boxCanvas();
    });
  },
  watch: {
    pieData: {
      handler(val) {
        this.$nextTick(() => {
          const pieChart = document.getElementById('pieChart');

          switch (val.length) {
            case 4:
            case 5:
            case 6:
              pieChart.style.height = 320 + 'px';
              break;
            case 7:
            case 8:
            case 9:
              pieChart.style.height = 340 + 'px';
              break;
            case 10:
            case 11:
            case 12:
              pieChart.style.height = 360 + 'px';
              break;
            case 13:
              pieChart.style.height = 380 + 'px';
              break;
            default:
              pieChart.style.height = 300 + 'px';
              break;
          }

          this.chart.clear(); // 清除上一次数据

          this.boxCanvas();
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.pie {
  .pie-title {
    font-size: 14px;
    font-weight: 500;
    color: #454458;
    margin-left: 20px;
    margin-top: 10px;
  }
  .record {
    padding: 62px 0;
    .van-image {
      margin: 0 auto;
      display: block;
    }
    p {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #adadad;
    }
  }
}
</style>
