<template>
  <div class="broken">
    <p class="broken-title">折线图</p>

    <canvas v-show="!showChart" id="boxCanvas" class="boxCanvas" style="width: 100%; height: 276px;"></canvas>
    <div class="record" v-show="showChart">
      <van-image :src="require('@/assets/images/accounts/none-record.png')" width="39vw" />
      <p>暂无记录～</p>
    </div>
  </div>
</template>

<script>
import F2 from '@antv/f2';

import { Image as VanImage } from 'vant';

export default {
  components: {
    VanImage
  },
  props: {
    BrokenData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chart: {},
      showChart: false
    };
  },

  methods: {
    boxCanvas() {
      this.chart = new F2.Chart({
        id: 'boxCanvas',
        pixelRatio: window.devicePixelRatio
      });

      const chart = this.chart;

      if (!this.BrokenData.length) {
        this.showChart = true;
        return;
      }
      this.showChart = false;

      chart.source(this.BrokenData);
      chart.tooltip({
        showCrosshairs: true
      });

      chart.scale({
        createDate: {
          range: [0, 1]
        },
        amount: {
          tickCount: 5,
          min: 0,
          alias: '单位/元'
        }
      });

      chart.axis('createDate', {
        label: function label(text, index, total) {
          const textCfg = {};
          if (index === 0) {
            textCfg.textAlign = 'left';
          } else if (index === total - 1) {
            textCfg.textAlign = 'right';
          }
          return textCfg;
        }
      });

      chart
        .area()
        .position('createDate*amount')
        .color('l(90) 0:#96e8d9 0.5:#c1f1e9 1:#fff')
        .style({
          fillOpacity: 0.8
        });
      chart
        .line()
        .position('createDate*amount')
        .color('l(90) 0:#96e8d9 0.5:#c1f1e9 1:#fff');
      // .color('#51CCB4');

      chart.render();
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.boxCanvas();
    });
  },
  watch: {
    BrokenData: {
      handler(val) {
        this.chart.clear(); // 清除上一次数据

        this.boxCanvas();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.broken {
  .broken-title {
    font-size: 14px;
    font-weight: 500;
    color: #454458;
    margin-left: 20px;
    margin-top: 10px;
  }
  .record {
    padding: 62px 0;
    .van-image {
      margin: 0 auto;
      display: block;
    }
    p {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #adadad;
    }
  }
}
</style>
