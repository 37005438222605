<template>
  <div class="histo">
    <div class="histo-title">
      <h3>{{ histoData.active == 0 ? '收入' : '支出' }}TOP5排行榜</h3>
      <p>{{ histoData.timeStr }}</p>
    </div>

    <div class="histo-container" v-if="histoData.tallyChartDataOfBar.length">
      <div class="box" v-for="(item, index) in histoData.tallyChartDataOfBar" :key="index">
        <div class="title">
          <div class="left">
            <p>{{ index + 1 }}</p>
            <span>{{ item.operateName }}</span>
          </div>
          <div class="right">{{ item.percent + '%' }}</div>
        </div>
        <div class="bar">
          <p :style="randomRgb(item.percent)"></p>
        </div>
      </div>
    </div>
    <div class="record" v-else>
      <van-image :src="require('@/assets/images/accounts/none-record.png')" width="39vw" />
      <p>暂无记录～</p>
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from 'vant';
export default {
  components: {
    VanImage
  },
  props: {
    histoData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },

  methods: {
    randomRgb(width) {
      var R = Math.floor(Math.random() * 255);
      var G = Math.floor(Math.random() * 255);
      var B = Math.floor(Math.random() * 255);
      return { background: 'rgb(' + R + ',' + G + ',' + B + ')', width: width + '%' };
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.histo {
  padding: 10px 20px;
  .histo-title {
    h3 {
      font-size: 14px;
      font-weight: 500;
      color: #454458;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #51ccb4;
    }
  }
  .histo-container {
    margin: 20px 0;
    .box {
      margin: 10px 0;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2px;
        .left {
          display: flex;
          align-items: center;
          p {
            width: 12px;
            height: 12px;
            margin-right: 6px;
            background: rgba(51, 70, 129, 0.05);
            border-radius: 1px;
            font-size: 10px;
            font-weight: 400;
            text-align: center;
            color: #1d1d1d;
            line-height: 12px;
          }
          span {
            font-size: 10px;
            font-weight: 400;
            color: #1d1d1d;
          }
        }
        .right {
          font-size: 8px;
          font-weight: 400;
          color: #1d1d1d;
        }
      }
    }
    .bar {
      overflow: hidden;
      width: 100%;
      height: 7px;
      background: rgba(51, 70, 129, 0.03);
      border-radius: 1px;
      p {
        height: 100%;
        border-radius: 1px;
      }
    }
  }
  .record {
    padding: 62px 0;
    .van-image {
      margin: 0 auto;
      display: block;
    }
    p {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #adadad;
    }
  }
}
</style>
